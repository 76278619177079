import { Toaster } from "react-hot-toast";
import { WagmiProvider } from "wagmi";
import { Home } from "@/src/pages/home";
import { Rewards } from "./pages/rewards";
import { Farms } from "./pages/farms";
import { FlareBurn2MintPage } from "./pages/burn2mint";
import { Vote } from "./pages/vote";
import { ChadPage } from "./pages/chad";
import { wagmiConfig } from "@/src/configs/web3Modal";
import { BalanceProvider, RebaseProvider, FarmProvider } from "@/src/context";
import { MetricsProvider } from "@/src/context";
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import "@/src/configs/web3Modal";
import { Dashboard } from "./pages/dashboard";
import { Metrics } from "./views/home";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApprovePage } from "./pages/approve";

const queryClient = new QueryClient()

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
       <QueryClientProvider client={queryClient}>
      <BalanceProvider>
        <MetricsProvider>
          <FarmProvider>
          <RebaseProvider>
        <Router>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Rewards" element={<Rewards />} />
          <Route path="Chad" element={<ChadPage />} />
          <Route path="Farms" element={<Farms />} />
          <Route path="Approver" element={<ApprovePage />} />
          <Route path="Metrics" element={<Dashboard />} />
          <Route path="FB2M" element={<FlareBurn2MintPage />} />
          </Routes>
        </Router>
        <Toaster position="top-center" />
        </RebaseProvider>
        </FarmProvider>
        </MetricsProvider>
      </BalanceProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
