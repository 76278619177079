import { Card } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { useFarms } from "@/src/hooks/useFarms";
import { Timer } from "@/src/components/deadlineTimer";
import { useStake } from "@/src/hooks/useStake";
import { Button } from "@/src/components";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";


export const CnyxBalance = () => {
  const { balance } = useBalances();
  const { frbMinted, minted, deadline } = useFarms();
  const { onUseFaucet } = useStake();
  const { address, isConnected } = useAccount();

  const handleFaucet = async () => {
    if (!isConnected) return;

    toast.promise(onUseFaucet(), {
      loading: "Transaction is in progress",
      success: "Transaction successfully completed",
      error: (data) => data,
    });
  };
  return (
    <Card className="md:h-5/6 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Balances</h2>
          </span>

          <div>
            
          </div>
        </span>

        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(balance).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">CRB</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(frbMinted).toFixed(2)} ({parseFloat((frbMinted/minted)*100).toFixed(2)}%)
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            My B2M Balance
          </h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(2000000*(frbMinted/minted)).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            Estimated FRB Airdrop
          </h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(minted).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            Total Burned
          </h2>
        </div>  
        <div>
        <h2 className="text-xl font-semibold text-foreground/50">
            * FRB airdrop depends on the total amount of CRB burned.
        </h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseInt(deadline) > 0 ? <Timer time={parseInt(deadline)}/> : "00:00:00:00"}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Deadline</h2>
        </div>
      </div>
    </Card>
    
  );
};
