import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";


export const ApproveCard = () => {
  const { chain } = useAccount();
  const { onApprove } = useStake();
  const { address, isConnected } = useAccount();
  const { balance, stakeBalance, cnyxBalance, deadline } = useBalances();
  const submitButtonRef = useRef(null);

  const [{ isStake, placeholder }, setOption] = useState({
    isStake: true,
    placeholder: "Amount to burn",
  });
  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isStake)
      return toast.promise(onApprove(), {
        loading: "Transaction is in progress",
        success: "Successfully minted CRB",
        error: (data) => data,
      });
  };
  return (
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">

      <form onSubmit={handleSubmit}>
        <div className="mt-8 p-1 pr-0">
        </div>
        <div className="border-t-2 border-foreground/30" />
        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
          {isConnected && <Button ref={submitButtonRef} type="submit">Approve All</Button>}
          
        </div>
      </form>
    </div>
  );
};
