import { FileAdd } from "@/src/icons";

export const navLinks = [
  {
    icon: "",
    label: "Burn2Mint Flare",
    href: "/FB2M",
  },
  {
    icon: "",
    label: "Stake",
    href: "/",
  },
  {
    icon: "",
    label: "Auto Harvest",
    href: "/chad",
  },
  {
    icon: "",
    label: "Farms",
    href: "/farms",
  },
  {
    icon: "",
    label: "Rewards",
    href: "/rewards",
  },
  {
    icon: "",
    label: "Vault",
    href: "https://vault.canary-reborn.xyz",
    target: "_blank",
  },
  {
    icon: "",
    label: "Docs",
    href: "https://docs.canary-reborn.xyz",
    target: "_blank",
  },
  {
    icon: "",
    label: "Metrics",
    href: "/metrics",
  },
];
